import '@popperjs/core'
import 'bootstrap/js/src/collapse'
import 'bootstrap/js/src/dropdown'
import $ from 'jquery'

const formToggler = function () {
  const form = $(this)
  $('.toggle', form).each(function () {
    const elem = $(this)
    const toggler = $(elem.data('target'))
    const checked = toggler.prop('checked')
    elem.toggle(checked)
    $('input, select, textarea', elem).filter('.required').attr('required', function () {
      return $(this).is(':visible')
    })
  })
}

const sun = $('.home-page-title .sun')
const sunshine = function () {
  const d = 45 // distance of Greenwich from the left in percent
  const date = new Date()
  const hours = date.getUTCHours()
  const minutes = (hours * 60) + date.getUTCMinutes()
  const minutesPerDay = 24 * 60
  const t = minutes * 100 / minutesPerDay
  const a = 40
  let m = (100 - t) - (50 - d)
  if (m < 0) {
    m += 100
  }

  const l = m - (a / 2)
  sun.css({
    left: `${l}%`,
    width: `${a}%`,
  })
}

if (sun.length) {
  window.setInterval(sunshine, 60_000)
  window.setTimeout(() => {
    sunshine()
  }, 500)
}

$('form')
  .each(formToggler)
  .on('change', formToggler)
  .on('click', '.remove-member', function () {
    const button = $(this)
    const row = button.closest('.family-member')
    row.remove()
    $('.family-member').each((index, elem) => {
      $(elem).find('[name^="family"]:visible').attr('name', (i, value) => {
        return value.replace(/family\[\d]/, `family[${index}]`)
      })
    })
  })

$('.add-member').click(function () {
  const button = $(this)
  const row = button.closest('.family-member')
  const index = $('.family-member').length - 1
  const newRow = row.clone()
  newRow.find('.add-member').remove()
  newRow.find('*').removeClass('d-none')
  newRow.find('[name^="family"]')
        .removeAttr('disabled')
        .attr('required', true)
        .attr('name', (idx, value) => {
          return value.replace('family[0]', `family[${index}]`)
        })
  row.before(newRow)
})

